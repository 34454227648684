<template>
  <b-card
      class="contract-edit-wrapper"
  >
    <!-- form -->
    <b-form id="contractForm" class="edit-form mt-2" v-if="contract">
      <b-row>
        <b-col md="12">
          <div class="inner-card-title">基本信息</div>
        </b-col>
        <b-col md="4" v-for="(data,index) in basicInfo" :key="index">
          <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType">
          </change-label>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">合同信息</div>
        </b-col>
        <b-col md="12">
          <b-card header="合同信息">
            <b-row>
              <b-col md="4" v-for="(data,index) in agreementInfo" :key="index">
                <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType">
                </change-label>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="12">
          <b-card header="双方信息">
            <b-row>
              <b-col md="3" v-for="(data,index) in bothInfo" :key="index">
                <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType">
                </change-label>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">交货地点</div>
        </b-col>
        <b-col md="4" v-for="data in placeInfo" :key="data.label">
          <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType">
          </change-label>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">结算条款</div>
        </b-col>
        <b-col :md="data.col || 4" v-for="data in settleInfo" :key="data.label">
          <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType"
                        :label-col="data.labelCol"
          >
          </change-label>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">决策评估</div>
        </b-col>
        <b-col :md="data.col || 4" v-for="data in assessInfo" :key="data.label">
          <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType"
                        :label-col="data.labelCol"
          >
          </change-label>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">银行账户</div>
        </b-col>
        <b-col md="6">
          <b-card header="收款方信息">
            <b-row>
              <b-col md="12" v-for="data in payInfo" :key="data.label">
                <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType"
                              :label-col="data.labelCol"
                >
                </change-label>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card header="付款方信息">
            <b-row>
              <b-col md="12" v-for="data in receiveInfo" :key="data.label">
                <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType"
                              :label-col="data.labelCol"
                >
                </change-label>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">其他信息</div>
        </b-col>
        <b-col md="12" v-for="data in otherInfo" :key="data.label">
          <change-label :label="data.label" :change-data="data.changeData" :convert-type="data.convertType"
                        :label-col="data.labelCol"
          >
          </change-label>
        </b-col>
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
/**
 * info格式
 * {
          col: 2,                                     b-col的md
          labelCol: 5,                                label的col
          label: '首款金额',                           label的名称
          changeData: data['firstPayAmount'],        数据源
          convertType: ''                            转换类型 详见ChangeLabel
        },
 * */
import {
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
  BMedia,
  BRow,
  BTable,
} from 'bootstrap-vue'
import { useRouter } from '@core/utils/utils'
import vSelect from 'vue-select'
import { onUnmounted, reactive, toRefs } from '@vue/composition-api'
import store from '@/store'
import contractStore from './contractStore'
import { useToast } from 'vue-toastification/composition'
import ChangeLabel from '@/views/components/xy/change-label'

export default {
  components: {
    ChangeLabel,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    vSelect,
    useToast,
    BCardBody,
    BTable,
    BFormDatepicker,
  },
  data() {
    return {
      dir: 'ltr',
    }
  },
  methods: {},
  setup() {
    const {
      route,
      router,
    } = useRouter()

    const state = reactive({
      type: 1,
      contract: undefined,
      basicInfo: [],
      agreementInfo: [],
      bothInfo: [],
      placeInfo: [],
      settleInfo: [],
      assessInfo: [],
      payInfo: [],
      receiveInfo: [],
      otherInfo: [],
    })

    if (!store.hasModule('contract')) store.registerModule('contract', contractStore)

    onUnmounted(() => {
      if (store.hasModule('contract')) store.unregisterModule('contract')

    })
    const view = function () {
      let id = route.value.query.id || 0
      state.type = route.value.query.type || 1
      store.dispatch('contract/changeView', { id: id })
          .then(res => {
            state.contract = res.data.data
            toPage(res.data.data)
          })
    }

    const toPage = function (data) {
      state.basicInfo = [
        {
          label: '标题',
          changeData: data['contractTitle']
        },
        {
          label: '申请时间',
          changeData: data['applierDate'],
        },
        {
          label: '流程编号',
          changeData: data['contractNo']
        },
        {
          label: '申请人',
          changeData: data['applierName']
        },
        {
          label: '申请部门',
          changeData: data['applierDepartmentName']
        },
        {
          label: '所属公司',
          changeData: data['applierCompanyName']
        },
      ]
      state.agreementInfo = [
        {
          label: '合同类型',
          changeData: data['agreementType'],
          convertType: state.type === 1 ? 'agreement_specific_type_CG' : 'agreement_specific_type_XS'
        },
        {
          label: '合同版本',
          changeData: data['agreementBatch'],
          convertType: 'agreement_batch'
        },
        {
          label: '是否有修改',
          changeData: data['agreementIsChanged'],
          convertType: 'yesno'
        },
        {
          label: '合同名称',
          changeData: data['agreementName'],
        },
        {
          label: '合同编号',
          changeData: data['agreementNo'],
        },
        {
          label: '合同份数',
          changeData: data['agreementCount'],
        },
        {
          label: '公章类型',
          changeData: data['sealType'],
          convertType: 'seal_type'
        },
        {
          label: '合同开始时间',
          changeData: data['agreementStart'],
          convertType: 'date'
        },
        {
          label: '合同结束时间',
          changeData: data['agreementEnd'],
          convertType: 'date'
        },
        {
          label: '适用哪国法律',
          changeData: data['referenceLaw'],
        },
        {
          label: '争议解决方式',
          changeData: data['differentIdeaMethod'],
          convertType: 'different_idea_method'
        },
        {
          label: '仲裁地点',
          changeData: data['arbitrate'],
          convertType: 'arbitrate_locale'
        },
        {
          label: '仲裁地点',
          changeData: data['arbitrateOther'],
        },
        {
          label: '原件类型',
          changeData: data['sourcefileType'],
          convertType: 'sourcefile_type'
        },
        {
          label: '其他',
          changeData: data['otherInfo'],
        },
        {
          label: '扫描件(双章)',
          changeData: data['attachmentsSourcefile'],
          convertType: 'file'
        },
      ]
      state.bothInfo = [
        {
          label: '我方单位',
          changeData: data['ourCompanyName'],
        },
        {
          label: '我方负责人',
          changeData: data['ourManageName'],
        },
        {
          label: '我方联系人',
          changeData: data['ourManageTel'],
        },
        {
          label: '我方邮箱',
          changeData: data['ourManageEmail'],
        },
        {
          label: '对方单位',
          changeData: data['otherCompanyName'],
        },
        {
          label: '对方负责人',
          changeData: data['otherContactName'],
        },
        {
          label: '对方联系人',
          changeData: data['otherContactTel'],
        },
        {
          label: '对方邮箱',
          changeData: data['otherContactEmail'],
        },
      ]
      state.placeInfo = [
        {
          label: '交货地点',
          changeData: data['getgoods'],
          convertType: 'getgoods'
        }
      ]
      state.settleInfo = [
        {
          label: '资金流向',
          changeData: data['moneyFlow'],
          convertType: state.type === 1 ? 'supplier_money_flow_CG' : 'supplier_money_flow_XS'
        },
        {
          label: state.type === 1 ? '提货方式' : '发货方式',
          changeData: data['pickMethod'],
          convertType: state.type === 1 ? 'pickup_method_CG' : 'pickup_method_XS'
        },
        {
          label: state.type === 1 ? '提货方式合同页码' : '发货方式合同页码',
          changeData: data['pickMethodPage'],
        },
        {
          label: '天数',
          changeData: data['agreementPayDays'],
        },
        {
          label: state.type === 1 ? '收款方式' : '付款方式',
          changeData: data['agreementPayMethod'],
          convertType: 'agreement_pay_method'
        },
        {
          label: '保证金',
          changeData: data['deposit'],
        },
        {
          label: '定金',
          changeData: data['bargain'],
        },
        {
          label: state.type === 1 ? '预付款' : '预收款',
          changeData: data['prepay'],
        },
        {
          label: '账期描述',
          changeData: data['paymentInfo'],
        },
        {
          label: '账期天数',
          changeData: data['paymentdays'],
        },
        {
          label: '结算条款合同页码',
          changeData: data['balancePage'],
        },
        {
          label: '结算币',
          changeData: data['balanceCurrency'],
        },
        {
          label: state.type === 1 ? '其他费用收款描述' : '其他费用付款描述',
          changeData: data['otherfees'],
        },
        {
          label: '逾期违约金比率(%/日)',
          changeData: data['breakPromisePercent'],
        },
        {
          label: state.type === 1 ? '我方收取发票类型' : '我方开立发票类型',
          changeData: data['billType'],
          convertType: 'invoice_type'
        },
        {
          label: '税率',
          changeData: data['taxRate'],
          convertType: 'tax_rate'
        },
        {
          label: '发票和税率合同页码',
          changeData: data['billFaxPage'],
        },
        {
          label: '',

        },
        {
          col: 2,
          labelCol: 5,
          label: '首款比例/%',
          changeData: data['firstPayPercent'],
        },
        {
          col: 2,
          labelCol: 5,
          label: '首款金额',
          changeData: data['firstPayAmount'],
        },
        {
          col: 4,
          label: '首款付款条件',
          changeData: data['firstPayCondition'],
        },
        {
          col: 4,
          label: '首款预计付款时间',
          changeData: data['firstPayPaydate'],
          convertType: 'date'
        },
        {
          col: 2,
          labelCol: 5,
          label: '中期款比例/%',
          changeData: data['middlePayPercent'],
        },
        {
          col: 2,
          labelCol: 5,
          label: '中期款金额',
          changeData: data['middlePayAmount'],
        },
        {
          col: 4,
          label: '中期款付款条件',
          changeData: data['middlePayCondition'],
        },
        {
          col: 4,
          label: '中期款预计付款时间',
          changeData: data['middlePayPaydate'],
          convertType: 'date'
        },
        {
          col: 2,
          labelCol: 5,
          label: '尾款比例/%',
          changeData: data['lastPayPercent'],
        },
        {
          col: 2,
          labelCol: 5,
          label: '尾款金额',
          changeData: data['lastPayAmount'],
        },
        {
          col: 4,
          label: '尾款付款条件',
          changeData: data['lastPayCondition'],
        },
        {
          col: 4,
          label: '尾款预计付款时间',
          changeData: data['lastPayPaydate'],
          convertType: 'date'
        },
        {
          col: 12,
          labelCol: 1,
          label: '结算方式',
          changeData: data['balancemethod'],
        },
        {
          col: 12,
          labelCol: 1,
          label: state.type === 1 ? '提货方式合同截图' : '发货方式合同截图',
          changeData: data['attachmentsPickMethod'],
          convertType: 'file'
        },
        {
          col: 12,
          labelCol: 1,
          label: '结算条款截图',
          changeData: data['attachmentsBalance'],
          convertType: 'file'
        },
        {
          col: 12,
          labelCol: 1,
          label: '发票及税率截图',
          changeData: data['attachmentsBillfax'],
          convertType: 'file'
        },
      ]
      state.assessInfo = [
        {
          label: '产品类别',
          changeData: data['categoryName'],
        },
        {
          label: '相关产品',
          changeData: data['productDetail'],
        },
        {
          label: '供应商数量',
          changeData: data['supplierCount'],
        },
        {
          label: '供方来源',
          changeData: data['provideSource'],
          convertType: 'provide_source'
        },
        {
          label: '供应商推荐人',
          changeData: data['supplierReference'],
        },
        {
          label: '供应商引入理由',
          changeData: data['supplierReason'],
        },
        {
          label: '其他理由描述',
          changeData: data['otherReason'],
        },
        {
          label: '供应商评级',
          changeData: data['supplierLevel'],
          convertType: 'company_level'
        },
      ]
      state.payInfo = [
        {
          label: '付款人银行及支行名称',
          changeData: data['payBankName'],
        },
        {
          label: '付款人银行及支行地点',
          changeData: data['payBankAddress'],
        },
        {
          label: '付款人开户名称',
          changeData: data['payAccountName'],
        },
        {
          label: '付款人银行账号',
          changeData: data['payAccountNo'],
        },
        {
          label: '付款人是否境外',
          changeData: data['payIsoutlands'],
          convertType: 'yesno'
        },
      ]
      state.receiveInfo = [
        {
          label: '付款人银行及支行名称',
          changeData: data['receiveBankName'],
        },
        {
          label: '付款人银行及支行地点',
          changeData: data['receiveBankAddress'],
        },
        {
          label: '付款人开户名称',
          changeData: data['receiveAccountName'],
        },
        {
          label: '付款人银行账号',
          changeData: data['receiveAccountNo'],
        },
        {
          label: '付款人是否境外',
          changeData: data['receiveIsoutlands'],
          convertType: 'yesno'
        },
      ]
      state.otherInfo = [
        {
          labelCol: 1,
          label: '变更理由及影响',
          changeData: data['oaReason'],
        },
        {
          labelCol: 1,
          label: '备注',
          changeData: data['memo'],
        },
        {
          labelCol: 1,
          label: '相关文档',
          changeData: data['attachmentsName'],
          // convertType: 'file'
        },
        {
          labelCol: 1,
          label: '相关附件',
          changeData: data['attachmentsIds'],
          convertType: 'file'
        },
      ]

    }

    view()

    const cancel = () => {
      router.go(-1)
    }
    return {
      view,
      cancel,
      ...toRefs(state)
    }

  },
  watch: {},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
